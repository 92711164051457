<template>
  <div>
    <div
      v-show="visibleCount"
      class="shop__results"
    >
      <product-item
        v-for="product in products"
        :key="product.id"
        :product="product"
      >
      </product-item>
    </div>
    <div
      v-show="!visibleCount && filters.fulltext"
    >
      <p>Не найдено услуг, удовлетворяющих поисковому запросу "<b>{{ filters.fulltext }}</b>"</p>
    </div>
  </div>
</template>

<script>

import ProductItem from '@/components/ProductItem'
export default {
  name: 'ProductsList',
  props: ['products', 'filters'],

  components: {
    ProductItem
  },

  computed: {
    visibleCount() {
      return this.products.filter(item => item.visible).length
    }
  }
}
</script>

<style lang="scss" scoped>
.shop__results {
  margin-top: 30px;
}
</style>

<template>
  <div
    v-show="product.visible"
    :class="['shop__results-item', {'active': isExpanded}]"
  >
    <div
      @click.prevent="isExpanded = !isExpanded"
      class="shop__results-item__top"
    >
      <span
        :class="['shop__results-item__status', product.type, {'light-blue': isProduct, 'blue': isPackage}]"
      >{{product.type}}</span>
      <span class="shop__results-item__title">{{ product.name }}</span>
      <div class="shop__results-item__top-price-wrap">
        <span class="shop__results-item__price">{{ product.price_discount | formatPrice }}</span>
        <button class="shop__results-item__btn"></button>
      </div>
    </div>
    <div class="shop__results-item__info">
      <p class="shop__results-item__info-text">
        {{ product.description }}
        <span
          v-if="isPackage"
        >
          <br>
          <br>
          <span><b>В состав этого пакета входят следующие услуги:</b><br></span>
          <span v-for="(i, idx) in product.included_items" :key="idx">
            <span>{{ idx+1 }}. {{ i.name }}</span><br>
          </span>
        </span>
      </p>
      <div class="shop__results-item__info-right">
        <p class="shop__results-item__info-right__time"><span>Срок исполнения:</span>&nbsp;<span>{{ product.processing_time.value }}&nbsp;{{ humanize_processing_time }}</span></p>
        <button
          @click.prevent="$bus.emit('addToCart', product.id)"
          :disabled="isInCart"
          class="btn middle btn--cart"
        >
          <svg-cart/>
          <span>{{ isInCart ? 'В КОРЗИНЕ' : 'В КОРЗИНУ' }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SvgCart from '@/assets/svg/cart-white.svg'
import { mapGetters } from 'vuex'
export default {
  name: 'ProductItem',
  props: ['product'],

  components: {
    SvgCart
  },

  data: () => ({
    isExpanded: false
  }),

  computed: {
    ...mapGetters({cartItems: 'items'}),
    type() {
      return (this.product.included_items) ? 'Пакет' : 'Справка'
    },
    isProduct() {
      return !(this.product.included_items)
    },
    isPackage() {
      //console.log('this.included_items → ', this.product.included_items)
      return !!(this.product.included_items)
    },
    humanize_processing_time() {
      // @todo: переделать в пайп
      switch (this.product.processing_time.type) {
        case 'days': return 'р.д.'
      }
    },
    isInCart() {
      return !!(this.cartItems.filter(c => c.id === this.product.id).length)
    }
  }
}
</script>

<style lang="scss" scoped>
.shop__results-item {
  display: flex;
  flex-direction: column;
  margin-top: 5px;

  &.active {
    .shop__results-item__info {
      display: flex;
    }
  }
}

.shop__results-item__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FEFEFE;
  border-radius: 18px 18px;
  padding: 17px 20px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.04);
  transition: all .2s ease-in;
  cursor: pointer;

  &:hover {
    background: rgba(47, 107, 240, .03);
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
  }

  .shop__results-item.active & {
    background: rgba(47, 107, 240, .01);
  }

  @include sm {
    align-items: flex-start;
  }


  @include lg {
    padding: 17px 20px;
  }
}

.shop__results-item__info {
  display: none;
  justify-content: space-between;
  padding: 27px 20px 17px 20px;
  background: #F7F7F9;
  border-radius: 0 0 18px 18px;
  transform: translateY(-10px);

  @include sm {
    flex-wrap: wrap;
  }
}

.shop__results-item__info-text {
  width: 70%;
  text-align: left;
  font-size: 12px;

  @include sm {
    width: 100%;
  }
}

.shop__results-item__info-right {
  width: 30%;
  display: flex;
  flex-direction: column;
  font-weight: 600;

  @include sm {
    width: 100%;
  }
}

.shop__results-item__status {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;

  &.blue {
    background: #2F6BF0;
  }

  &.light-blue {
    background: #94E4F9;
  }
}

.shop__results-item__title {
  position: relative;
  top: 50%;
  transform: translateY(15%);
  width: 90%;
  text-align: left;
  padding: 0 10px;

  @include sm {
    width: 70%;
    top: unset;
    transform: translateY(0);
  }
}

.shop__results-item__title, .shop__results-item__price {
  font-weight: 600;
  font-size: 14px;
}

.shop__results-item__price {
  padding-right: 10px;
}

.shop__results-item__btn {
  display: block;
  width: 31px;
  height: 31px;
  border: 0;
  background-color: unset;
  background-image: url("/icons/arrow-bottom.svg");
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: transform ease-in .2s;

  .shop__results-item.active & {
    transform: rotate(180deg);
    background-image: url("/icons/arrow-bottom-active.svg");
  }

  @include sm {
    margin-top: 10px;
  }
}

.shop__results-item__info-right__time {
  margin: 0 auto;
  font-size: 12px;

  @include sm {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
}

.shop__results-item__top-price-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 14%;

  @include sm {
    width: 20%;
    flex-direction: column;
    align-items: flex-end;
  }
}
</style>
